/* Fonts */
@font-face {
  font-family: 'Scandinavian New Regular';
  src: url('./assets/fonts/scandinaviannew_400-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/scandinaviannew_400-regular-webfont.woff') format('woff'),
       url('./assets/fonts/scandinaviannew-regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Scandinavian New Black';
  src: url('./assets/fonts/scandinaviannew_900-black-webfont.woff2') format('woff2'),
       url('./assets/fonts/scandinaviannew_900-black-webfont.woff') format('woff'),
       url('./assets/fonts/scandinaviannew-black.ttf') format('ttf');
}

body {
  margin: 0;
  font-family: 'Scandinavian New Regular', sans-serif;
  background-color: #D9D9D9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
